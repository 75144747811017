import React, { useState, useLayoutEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { CustomColumnContainer, ThreeColumnContainer, TwoColumnContainer } from '../Shared/Formelements';
import { Overlay } from '../UI/Overlay';
import { Reloads } from '../../rdx/StoreFillerHandler';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { Operations } from '../../rdx/Operations';
import store from '../../rdx/store';
import { JwtPlantImage } from '../UI/ImageElements';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { Padder, ScreenHeader } from '../UI/Elements';
import { RdxStore } from '../../rdx/ReduxTypes';
import { PlantListType } from '../../rdx/Redux.DataReducerType';
import { SelectOptionStringType } from '../UI/UITYpes';

export const Lists = () => {

    const listsObject: { [key: string]: PlantListType } = useSelector((state: RdxStore) => state.data.plantlists);
    const lists: string[] = Object.keys(listsObject ?? {}).filter(k => k !== "loaded");

    const [filter, setFilter] = useState<string>(null);

    const [deleting, setDeleting] = useState(false);
    const [working, setWorking] = useState(false)

    const [listId, setListId] = useState<string>("");
    const [listName, setListName] = useState<string>("");
    const [listTags, setListTags] = useState<string[]>([]);

    const [listDescription, setListDescription] = useState<string>("");
    const [plants, setPlants] = useState([]);
    const [allTags, setAllTags] = useState<string[]>([]);
    const [allTagsSelected, setAllTagsSelected] = useState<string[]>([]);


    const load = () => {
        setWorking(true);
        clear();
        setWorking(false);
    };

    const clear = () => {
        setListId("");
        setListName("");
        setListDescription("");
        setListTags([]);
        setDeleting(false);
        setPlants([]);
    }

    const saveClicked = () => {

        if (!listName) {
            MessageBoxes.warning("Ange namn");
            return;
        }

        // update
        const postObj = {
            name: listName,
            description: listDescription,
            tags: listTags
        };

        if (!listId) {
            setWorking(true);
            FetchHandler.postJson("/api/plantlist/add", postObj).then(data => {
                clear();
                load();
                setWorking(false);
                Reloads.reloadPlantlists();
            });
        } else {
            setWorking(true);
            FetchHandler.postJson("/api/plantlist/" + listId + "/update", postObj).then(data => {
                clear();
                load();
                setWorking(false);
                Reloads.reloadPlantlists();
            });
        }
    };
    const deleteList = () => {
        setWorking(true);
        FetchHandler.postJson("/api/plantlist/" + listId + "/delete", {}).then(data => {
            clear();
            load();
            setWorking(false);
        });
    }

    useLayoutEffect(() => {
        Reloads.reloadPlantlists();
        load();
    }, []);

    const calcAllTags = () => {
        if (listsObject) {
            const ts: string[][] = Object.keys(listsObject).map(key => listsObject[key].tags ?? []);
            let tags = {};
            ts.forEach(_ts => {
                if (_ts) {
                    _ts.forEach(_t => tags[_t] = _t);
                }
            });
            console.log("tags:", tags);
            const ret = Object.keys(tags);
            setAllTags(ret);
            return ret;
        } else {
            console.log("tags...");
            return [];
        }
    }

    useLayoutEffect(() => {
        calcAllTags();
    }, [, listsObject]);

    const loadPlants = (id: string) => {
        FetchHandler.getJson("/api/plantlist/" + id + "/plants").then(data => {
            setPlants(data);
        });
    }

    const selectList = (item) => {
        if (listId != item?.id) {
            setListId(item.id);
            setListName(item.name);
            setListDescription(item.description);
            setListTags(item?.tags ?? []);
            setDeleting(false);
            loadPlants(item.id);
        } else {
            clear();
        }
    }
    const deletePlant = (id) => {
        console.log("delete plantid: ", id, "listId: ", listId);
        Operations.removePlantsFromList(listId, [id]).then(success => {
            if (success) {
                MessageBoxes.info("Växten togs bort från listan");
                loadPlants(listId);
            } else {
                MessageBoxes.warning("Operationen misslyckades");
            }
        })
    }

    const handleInputChange = () => {
        const str = MessageBoxes.prompt("Ny tag");
        if (str) {
            setAllTags([...(calcAllTags()), str]);
            setListTags([...listTags, str]);
        }
    };

    return (
        <div>
            <Overlay visible={working} />
            <ScreenHeader text="Växtlistor" icon={AdminIcons.list} />

            {/* Single */}
            <CustomColumnContainer gridTemplateColumns={'300px auto 300px 300px'}>
                {/* 1 */}
                <div className="form-large">
                    <label>Namn</label>
                    <input type="text" value={listName} onChange={(e) => { setListName(e.target.value) }} />
                </div>
                {/* 2 */}
                <div className="form-large">
                    <label>Beskrivning</label>
                    <input type="text" value={listDescription} onChange={(e) => { setListDescription(e.target.value) }} />
                </div>
                {/* 3 */}
                <div className="form-large">
                    <label>Tags</label>
                    <div>
                        <Select
                            isMulti
                            value={listTags?.map(t => { return { value: t, label: t } })}
                            options={allTags?.map(t => { return { value: t, label: t } })}
                            onChange={(e: SelectOptionStringType[]) => {
                                setListTags(e?.map(t => t?.value));
                                console.log(e);
                            }}
                        />

                        <button className='btn-mini' onClick={handleInputChange}>
                            Lägg till tag
                        </button>
                    </div>
                </div>




                <div className="form-large">

                    <ThreeColumnContainer>
                        <div>
                            <label>&nbsp;</label>
                            <button className="btn-link" onClick={saveClicked}>
                                {!listId && (<span>Lägg till</span>)}
                                {listId && (<span>Uppdatera</span>)}
                            </button>
                        </div>
                        {deleting && (
                            <div>
                                <label>&nbsp;</label>
                                <button className="btn-link btn-link-warning" onClick={() => { deleteList() }}>Vill du radera?</button>
                            </div>
                        )}
                        <div className="right">
                            <label>&nbsp;</label>

                            {listId && (
                                <button className="btn-link" onClick={() => {
                                    setDeleting(!deleting);
                                }}>
                                    {deleting && (<>Ångra</>)}
                                    {!deleting && (<>Radera</>)}

                                </button>
                            )}

                        </div>
                    </ThreeColumnContainer>
                </div>
            </CustomColumnContainer>
            <hr />


            <TwoColumnContainer>

                <div>
                    <div>
                        <CustomColumnContainer gridTemplateColumns='50% auto'>
                            <div className='form-large'>
                                <label>Filtrera namn/beskrivning</label>
                                <input type="text" value={filter} onChange={(e) => { setFilter(e.target.value?.toLowerCase()) }} />
                            </div>
                            <div className='form-large'>
                                <label>Filtrera taggar</label>
                                <div className='tag-container'>
                                    {allTags?.map(t => (
                                        <span key={t} className={'tag tag-small' + (allTagsSelected?.indexOf(t) >= 0 ? " tag-selected" : "")} onClick={() => {
                                            if (allTagsSelected?.indexOf(t) >= 0) {
                                                setAllTagsSelected(allTagsSelected?.filter(n => n !== t));
                                            } else {
                                                setAllTagsSelected([...allTagsSelected, t]);
                                            }
                                        }}>
                                            {t}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </CustomColumnContainer>
                    </div>
                    <ListTable selectList={selectList} filter={filter} allTagsSelected={allTagsSelected} />
                    <Padder.Horizontal size='20px' />
                </div>

                <div>
                    <PlantList plants={plants} deletePlant={deletePlant} />
                </div>

            </TwoColumnContainer>
        </div>
    )

}

const ListTable = ({ selectList, filter, allTagsSelected }: { selectList(e: any): void, filter: string, allTagsSelected: string[] }) => {
    const plantlistsObj = useSelector(state => state.data.plantlists);
    const lists = Object.keys(plantlistsObj ?? {})
        .filter(k => k !== "loaded")
        .map(k => plantlistsObj[k])
        .filter((l: PlantListType) => {
            if ((allTagsSelected?.length ?? 0) === 0) {
                console.log("1");
                return true;
            } else if (allTagsSelected.filter(value => l?.tags?.includes(value))?.length > 0) {
                console.log("2");
                return true;
            } else {
                console.log("3");
                return false;
            }
        })
        .filter((l: PlantListType) => {
            if (!filter) { return true; }
            else {
                return l.name.toLowerCase()?.includes(filter) ||
                    l.description.toLowerCase()?.includes(filter);
                //  || JSON.stringify(l.tags).toLowerCase()?.includes(filter);
            }
        });

    return (
        <div>

            <div>
                <p>
                    Antal: {lists.length}
                </p>
            </div>

            <table className="tbl">
                <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Beskrivning</th>
                        <th>Taggar</th>
                        {/* <th>Antal växter</th> */}
                    </tr>
                </thead>
                <tbody>
                    {lists && lists.map(item => (
                        <ListRow key={item.id} item={item} selectList={selectList} />
                    ))}
                </tbody>

            </table>
        </div>
    )
}

const ListRow = ({ item, selectList }) => {
    return (
        <>
            <tr data-listid={item.id} className='list-table-row'>
                <td style={{ whiteSpace: "nowrap" }}>
                    <div className='name'>
                        {item?.name}
                    </div>
                    <div className='description'>
                        {item?.description}
                    </div>
                    <div className='tag-container'>
                        {item?.tags?.map(t => (<span key={t} className='tag tag-small'>{t}</span>))}
                    </div>
                </td>
                <td>{item?.numberOfPlants}</td>
                <td>
                    <div style={{ cursor: "pointer" }} onClick={() => {
                        navigator.clipboard.writeText(item?.id);
                        MessageBoxes.info("ID kopierad")
                    }}>
                        <code>ID</code>
                    </div>
                </td>
                <td style={{ width: "150px" }}>
                    <button className="btn-link" onClick={() => { selectList(item) }}>
                        Välj
                    </button>
                </td>
            </tr>
        </>
    )
}

const PlantList = ({ plants, deletePlant }) => {
    return (
        <>
            <p>
                Växter: {plants?.length}
            </p>
            <div className='sticky'>
                <table className="tbl">
                    <thead>
                        <tr>
                            <th>Bild</th>
                            <th>Namn</th>
                            <th>Sort</th>
                            <th>Latin</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {plants && plants.map(plant => (
                            <tr key={plant.id}>
                                <td>
                                    <JwtPlantImage plantImageId={plant?.imageid} small={true} />
                                </td>
                                <td>{plant?.name}</td>
                                <td>{plant?.specifictype}</td>
                                <td>{plant?.namelatin}</td>
                                <td style={{ width: "200px" }}>
                                    <DeleteButton deleteTitle="Ta bort växten" OnDelete={() => deletePlant(plant.id)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}


const DeleteButton = ({ OnDelete, deleteTitle }) => {

    const [clickedOnce, setClickedOnce] = useState(false);


    return (
        <div>
            {clickedOnce === false && (
                <button className="btn-link" onClick={() => { setClickedOnce(true); }}>{deleteTitle}</button>
            )}
            {clickedOnce === true && (
                <>
                    <button className="btn-link" onClick={() => { setClickedOnce(false); }}>Ångra</button>
                    <button className="btn-link btn-link-warning" onClick={() => {
                        OnDelete();
                        setClickedOnce(false);
                    }}>{deleteTitle}</button>
                </>
            )}

        </div>
    )

}