import React from "react"
import { useDateHelper } from "../../hooks/useHelper";
import { useSignalRMessaging } from "../../rdx/useSignalR";
import { AdminIcons } from "../Shared/AdminIcon"
import { useStatisticsMenu } from "../Statistics/useStatisticsMenu";
import { ScreenHeader, ScreenMenu } from "../UI/Elements"


export const SignalRAdmin = () => {

    const handler = useSignalRMessaging();

    const dh = useDateHelper();

    const menu = useStatisticsMenu();
    return (
        <div>
            <ScreenHeader text="Aktiva användare" icon={AdminIcons.wifi} />
            <ScreenMenu items={menu?.items} />
            <div>
                <table className="tbl tbl-small">
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>Email</th>
                            <th>Rubrik</th>
                            <th>Action</th>
                            <th>Började session</th>
                            <th>Sågs senast</th>
                        </tr>
                    </thead>
                    <tbody>
                        {handler?.connectedUsers?.map(u => (
                            <tr key={u?.added}>
                                <td>{u?.name}</td>
                                <td>{u?.email}</td>
                                <td>{u?.header}</td>
                                <td>{u?.action}</td>
                                <td>{dh.timeAgo(u?.added)}</td>
                                <td>{dh.timeAgo(u?.ping)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    )
}