import React, { Component, useState } from 'react';
import { useSelector } from 'react-redux';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { Routes } from '../../Handlers/ViewHandler';
import { PlantOperations } from '../../rdx/Operations';
import { ThreeColumnContainer } from '../Shared/Formelements';
import { CancelIcon, RemoveIcon, SaveIcon } from '../Shared/Icons';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { ShareLinkElement } from '../UI/Elements';


interface IProps {
    history: any[]
}
interface IState {
    id?: string,
    model?: any,

}



export class EditPlant extends Component<IProps, IState> {
    static displayName = EditPlant.name;

    mounted: boolean;

    constructor(props) {
        super(props);

        this.mounted = false;
        this.state = {
            id: props.match.params.id
        };
        this.fieldChanged = this.fieldChanged.bind(this);
        this.saveClicked = this.saveClicked.bind(this);
        this.DeleteClicked = this.DeleteClicked.bind(this);

    }

    fieldChanged(e) {
        let m = this.state.model;
        m.fields[e.field] = e.value;
        this.setState({ model: m });
        console.log("fieldChanged:", e.field, e.value, m);
    }
    saveClicked() {
        const postObj = {
            id: this.state.model.id,
            name: this.state.model.fields.name,
            specifictype: this.state.model.fields.specifictype,
            namelatin: this.state.model.fields.namelatin,
            description: this.state.model.fields.description,
            planttype: this.state.model.fields.planttype,
            alternativenames: this.state.model.fields.alternativenames,
            alternativekeywords: this.state.model.fields.alternativekeywords
        }

        FetchHandler.postJson("/api/plants/updatebasicfields", postObj).then(response => {
            MessageBoxes.successAlert(response.success, "Växten sparades!");
        });
    }
    DeleteClicked() {
        PlantOperations.deletePlant(this.state.id).then(response => {
            if (response.success === true) {
                this.props.history.push(Routes.start);
            } else {
                MessageBoxes.warning("Failed!");
            }
        })
    }

    componentDidMount() {
        this.mounted = true;
        FetchHandler.getJson("/api/plants/" + this.state.id).then(data => {
            if (this.mounted) {
                this.setState({
                    model: data,
                })
            }
        })
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (<div>
            <RenderFlowerDetail model={this.state.model}
                onChange={this.fieldChanged} saveClicked={this.saveClicked}
                onDeleteClicked={this.DeleteClicked} />
        </div>)
    }
}


const RenderFlowerDetail = (props) => {

    const field_settings = useSelector(state => state.data.plantfieldsobject)
    const types_settings = useSelector(state => state.data.planttypesobject)

    const model = props.model;
    if (!model) { return (<div>N/A</div>) }

    const fields = model.fields;
    if (!fields) { return (<div>N/A</div>) }

    const basic_fields = ["name", "specifictype", "namelatin", "description", "planttype"];
    const onChange = (field, value) => {
        if (onChange) {
            props.onChange({ field: field, value: value });
        }
    };
    const saveClicked = () => {
        props.saveClicked();
    }

    return (
        <div>
            <h1>{fields.name}</h1>
            <h4>{model.plantTypeName}</h4>

            <hr />

            <ThreeColumnContainer>
                <div style={{ cursor: "pointer" }} onClick={() => {
                    navigator.clipboard.writeText(model.id);
                    MessageBoxes.info("ID kopierad: " + model.id)
                }}>
                    <code>ID: {model.id}</code>
                </div>

                <div></div>


                <div>
                    <div style={{ cursor: "pointer" }} onClick={() => {
                        navigator.clipboard.writeText(model?.fields?.short_id);
                        MessageBoxes.info("Kort ID kopierad: " + model?.fields?.short_id)
                    }}>
                        <code>ID (kort): {model?.fields?.short_id}</code>
                    </div>

                    <ShareLinkElement id={model?.fields?.short_id} type={"plant"} />
                    {/* <div style={{ cursor: "pointer" }} onClick={() => {
                        const url = "https://share.ohgarden.se/plant/" + model?.fields?.short_id;
                        navigator.clipboard.writeText(url);
                        MessageBoxes.info("Länk kopierad: " + url);
                    }}>
                        <code>Delningslänk: {"https://share.ohgarden.se/plant/" + model?.fields?.short_id}</code>
                    </div> */}

                </div>


            </ThreeColumnContainer>

            <hr />

            <ThreeColumnContainer>
                <PlantFieldEditItem label="Namn" value={fields.name} name="name" onChange={onChange} />
                <PlantFieldEditItem label="Sort" value={fields.specifictype} name="specifictype" onChange={onChange} />
                <PlantFieldEditItem label="Latinskt namn" value={fields.namelatin} name="namelatin" onChange={onChange} />

                <PlantFieldEditItem label="Alternativa namn" value={fields.alternativenames} name="alternativenames" onChange={onChange} />
                <PlantFieldEditItem label="Beskrivning" value={fields.description} name="description" onChange={onChange} large={true} />
                <div>
                    <PlantFieldEditItem label="Alternativa nyckelord" value={fields.alternativekeywords} name="alternativekeywords" onChange={onChange} large={true} />
                    {/* <PlantFieldEditItem label="Typ" value={types_settings[fields.planttype].name} name="type" onChange={onChange} /> */}

                    <div>
                        <div style={{ marginTop: "18px" }}>

                            <button className="btn pull-right" onClick={saveClicked}>
                                <SaveIcon /> Spara
                            </button>

                            <DeleteButton onClick={() => { if (props.onDeleteClicked) { props.onDeleteClicked() } }} />

                        </div>
                    </div>

                </div>
            </ThreeColumnContainer>

            <hr />

            <ThreeColumnContainer>

                {Object.getOwnPropertyNames(fields).filter(f => { return basic_fields.indexOf(f) < 0; }).map(f => (
                    <RenderFieldValue key={f} id={f} value={fields[f]} settings={field_settings[f]} />
                ))}

            </ThreeColumnContainer>

        </div>
    )
}

export const RenderFieldValue = (props) => {
    const settings = props.settings;

    if (!props.id || !settings) {
        return (null)
    }

    const _label = settings.name;
    let _value = props.value;

    if (Array.isArray(_value)) {
        const items = settings.selectOptions.filter(obj => _value.indexOf(obj.key) >= 0).map(obj => obj.name);
        _value = items.join(',');
    }
    if (settings.type === 7) {
        return (
            <PlantFieldItem label={_label} value={_value === null ? "-" : (_value ? "Ja" : "Nej")} preFormatted={null} />
        );
    }
    if (settings.type === 10) { // snippet
        return (
            <SnippetTextFieldItem label={_label} snippetId={props.value} />
        )
    }

    return (
        <PlantFieldItem label={_label} value={_value} preFormatted={null} />
    );
}

const SnippetTextFieldItem = ({ label, snippetId }) => {
    const snippetsObj = useSelector(store => store.data.snippets[snippetId]);
    return (
        <PlantFieldItem label={label + " (Snippet)"} value={snippetsObj?.snippetDescription} preFormatted={true} />
    );
}

const PlantFieldItem = ({ label, value, preFormatted }) => {
    if (!label) {
        return (null);
    }

    return (
        <div className="plantdetail-field">
            <div className="label">
                {label}
            </div>
            <div className={"value " + (preFormatted === true ? "pre-formatted" : "")}>
                {value}
            </div>
        </div>
    );
}

const PlantFieldEditItem = (props) => {
    const [value, setValue] = useState(props.value);
    const onChange = (e) => {
        const v = e.target.value;
        setValue(v);
        props.onChange(props.name, v);
    };

    return (
        <div className="plantdetail-field">
            <div className="label">
                {props.label}
            </div>
            <div className="value">
                {props.large && (
                    <textarea value={value} className="w100" onChange={onChange} style={{ height: "150px" }} />
                )}
                {!props.large && (
                    <input value={value} className="w100" onChange={onChange} />
                )}
            </div>
        </div>
    );
}


const DeleteButton = (props) => {
    const [step, setStep] = useState(1);

    if (step === 1) {
        return (
            <>
                <button className="btn" onClick={() => { setStep(2) }}>
                    <RemoveIcon /> Ta bort
                </button>
            </>
        )
    } else {

        return (
            <>
                <button className="btn" onClick={() => { setStep(1) }}>
                    <CancelIcon /> Ångra
                </button>
                <button className="btn" onClick={() => { if (props.onClick) { props.onClick(); } }}>
                    <RemoveIcon /> Ta bort
                </button>
            </>
        )
    }

}