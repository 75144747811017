import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import { Link, useHistory } from 'react-router-dom';
import QRCode from "qrcode.react";
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { Overlay } from './Overlay';
import { Dialog } from '../Shared/Dialog';
import { ClipBoardElement } from '../ClipBoard/ClipBoard';
import { SelectOptionStringType } from './UITYpes';
import { useSignalRMessaging } from '../../rdx/useSignalR';
// import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

export const Elements = {
    Form: ({ children }) => {
        return (
            <div className="form-large">
                {children}
            </div>
        )
    },
    Label: ({ text, className }: { text?: string, className?: string }) => {
        return (
            <label className={className} >{text}</label>
        )
    },
    Div: ({ text, className }: { text?: string, className?: string }) => {
        return (
            <div className={className} >{text}</div>
        )
    },
    TextInput: ({ value, onChange, className, style }) => {
        return (
            <input type="text" value={value} onChange={(e) => onChange(e.target.value)} className={className} style={style} />
        )
    },
    Select: ({ options, value, onChange, className, style, includeEmpty }: { options?: any, value?: any, onChange?: any, className?: any, style?: any, includeEmpty?: any }) => {
        return (
            <select value={value} onChange={(e) => onChange(e.target.value)} className={className} style={style}>
                {(includeEmpty === true) && (
                    <option value="">-</option>
                )}
                {options && options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
        )
    },
}

export const Tbl = {
    Td: ({ text, className }: { text: string, className?: string }) => {
        return (
            <td className={className}>
                {text}
            </td>
        )
    },
}


export const Spacer = {
    Vertical: ({ size }: { size: string }) => {
        return (
            <div style={{ height: size, width: "100%" }}></div>
        )
    }
}

export const Padder = {
    Horizontal: ({ size }: { size: string }) => {
        return (
            <div style={{ height: size, width: "100%" }}></div>
        )
    }
}

export const ScreenHeader = ({ text, icon, onReloadData }: { text: string, icon: string, onReloadData?(): void }) => {


    // const signalR = useSignalRMessaging();
    // useEffect(() => {
    //     signalR.ping(text);
    // }, [])


    return (
        <>
            <h2 className='h2-header'>
                <AdminIcon icon={icon} size="sm" />
                <div>
                    {text}
                </div>

                {onReloadData && (
                    <div className='h2-header-icon cursor-pointer' onClick={onReloadData}>
                        <AdminIcon icon={AdminIcons.refresh} />
                    </div>
                )}

                {/* <AdminIcon icon={AdminIcons.globe} size="sm" /> */}

            </h2>
        </>
    )
}



export const ConfirmDelete = ({ onDelete }: { onDelete(): void }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    return (
        <>
            {isDeleting && (
                <>
                    <button className="btn confirmdelete-delete" onClick={() => { onDelete(); setIsDeleting(false); }}>
                        Radera
                    </button>
                    <button className="btn confirmdelete-cancel" onClick={() => { setIsDeleting(false); }}>
                        Ångra
                    </button>
                </>
            )}
            {!isDeleting && (
                <button className="btn confirmdelete-delete" onClick={() => { setIsDeleting(true); }} >
                    Radera
                </button>
            )}
        </>
    )
}


export const EditableText = ({ text, onChange }: { text: string, onChange(e: string): void }) => {
    const [textValue, settextVal] = useState<string>(text);
    const [editing, setEditing] = useState<boolean>(false);

    const change = (e) => {
        settextVal(e);
    }

    return (
        <>
            {editing == false && (
                <span onDoubleClick={() => { setEditing(true) }}>{textValue}</span>
            )}

            {editing == true && (
                <input type={"text"} defaultValue={textValue} onChange={e => { change(e.target.value); }} onKeyUp={e => {
                    console.log("e.key:", e.key);
                    if (e.key === "Enter") {
                        if (textValue != text) {
                            onChange(textValue);
                        }
                        setEditing(false);
                    } else if (e.key === "Escape") {
                        settextVal(text);
                        setEditing(false);
                    }
                }} />
            )}
        </>
    )
}

export const SearchBox = ({ onSearch }: { onSearch(e: string): void }) => {
    const [search, setSearch] = useState<string>("")
    return (
        <input
            placeholder='Sök'
            type={"text"} onChange={e => setSearch(e.target.value)} onKeyUp={e => {
                if (e.key === "Enter") {
                    onSearch(search);
                }
            }} />
    )
}

export const ShareLinkElement = ({ id, type }: { id: string, type: "plant" | "post" | "user", }) => {
    const url = "https://share.ohgarden.se/" + type + "/" + (type == "plant" ? id.substring(0, 8) : id);
    const imageUrl = "/api/qrcode/" + type + "/" + (type == "plant" ? id.substring(0, 8) : id);
    const [showQr, setShowQr] = useState<boolean>(false)
    return (
        <div className='sharelinkelement'>
            <div className='copyable-text' onClick={() => {
                navigator.clipboard.writeText(url);
                MessageBoxes.info("Länk kopierad: " + url);
            }}>
                <code>{url}</code>
            </div>
            <div className='qr-btn' onClick={() => { setShowQr(true); }}>
                <AdminIcon icon={AdminIcons.qrcode} />
            </div>
            {showQr && (
                <Dialog
                    onCancel={null}
                    onOk={null}
                    width="600px"
                    height="400px">
                    <div className='share-qrcode' onClick={() => { setShowQr(false) }}>
                        <img src={imageUrl} width={350} height={350} loading="lazy" />
                    </div>
                </Dialog>
            )}
        </div>
    )
}


// Loading
export const LoadingBar = ({ loading }) => {
    return (
        <div className={'loadingbar ' + (loading ? "loadingbar-loading" : "")}></div>
    )
}


export const AdminMultiSelect = ({ value, options, onChange, placeholder, }: { value: SelectOptionStringType[], options: SelectOptionStringType[], onChange(e: SelectOptionStringType[]): void, placeholder?: string, }) => {
    return (
        <Select
            value={value}
            options={options}
            isMulti={true}
            onChange={onChange}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            placeholder={placeholder}
        />
    )
}

export type ScreenMenuItemType = {
    id: string,
    label: string,
    link: string,
}
export const ScreenMenu = ({ items }: { items: ScreenMenuItemType[] }) => {
    const history = useHistory();
    const [currentUrl, setCurrentUrl] = useState<string>(undefined);
    useEffect(() => {
        setCurrentUrl(window.location.pathname)
    }, []);

    return (
        <ScreenTabContainer>
            {items?.map(item => (
                <ScreenTabContainerItem
                    key={item?.id}
                    selected={item?.link === currentUrl}
                    label={item?.label}
                    onClick={() => history.push(item?.link)} />
            ))}
        </ScreenTabContainer>
    )
}

export type GenericTabType = {
    id: string
    label: string
    onClick(): void
    selected?: boolean
}
export const GenericTabs = ({ items }: { items: GenericTabType[] }) => {
    return (
        <ScreenTabContainer>
            {items?.map(item => (
                <ScreenTabContainerItem
                    key={item?.id}
                    selected={item?.selected}
                    label={item?.label}
                    onClick={item?.onClick} />
            ))}
        </ScreenTabContainer>
    )
}



export const ScreenTabContainer = ({ children }) => {
    return (
        <div className="screen-menu-container">
            <ul className="screen-menu">
                {children}
            </ul>
            <div className='screen-menu-filler'></div>
        </div>
    )
}

const ScreenTabContainerItem = ({ label, selected, onClick }) => {
    return (
        <li className={selected ? "selected" : ""} onClick={onClick}>
            {label}
        </li>
    )
}