const Routes = {
    start: "/",
    users: "/users",
    userViewDefinition: "/user/:id",
    getUserView: (id) => { return "/user/" + id; },
    plants: "/plants",
    addplant: "/plants/add",
    addnewplant: "/plants/addnewplant",
    editplant: "/plants/edit/:id",
    getplantroute: (id) => { return "/plants/edit/" + id },
    plantimages: "/plants/plantimages/:id",
    allplantimages: "/plants/images",
    getplantimagesroute: (id) => { return "/plants/plantimages/" + id },
    createplant: "/plants/createplant",
    planttypes: "/planttypes",
    plantfields: "/plantfields",
    fieldspertype: "/fieldspertype",
    planttags: "/planttags",
    images: "/images",
    listitems: "/misc/listitems",
    colors: "/misc/colors",
    gardenactivities: "/activities/gardenactivities",
    onboardinglists: "/onboarding/onboardinglists",
    discover: "/discover",
    plantnetlist: "/plantnetlist",
    systemsettings: "/systemsettings",
    dashboard: "/dashboard",
    toolsscreen: "/toolsscreen",
    snippets: "/misc/snippets",
    tools: "/misc/tools",
    lists: "/misc/lists",
    inspiration: "/inspiration",
    userplants: "/userplants",
    userplantimages: "/userplantimages",
    plantgroups: "/plantgroups",

    plantimageoperations: "/plantimageoperations",
    socialimageoperations: "/socialimageoperations",
    userplantimageoperations: "/userplantimageoperations",
    faq: "/faq",
    feedback: "/feedback",
    forum: "/forum",
    blog: "/blog",
    blogauthors: "/blogauthors",
    webforms: "/webforms",
    postsmanagement: "/postsmanagement",
    userstatus: "/userstatus",
    feed: "/feed",
    comments: "/comments",
    userlists: "/userlists",
    accountsadmin: "/accountsadmin",
    subscriptions: "/subscriptions",
    subscriptionswithoutuser: "/subscriptionswithoutuser",
    purchases: "/purchases",
    createqrcode: "/createqrcode",
    testscreen: "/testscreen",
    signalradmin: "/signalradmin",
    statistics: "/statistics",
    userstatistics: "/statistics/user",


    adadmin: "/adadmin",
    adclients: "/adclients",
    combines: "/combines",
    pointofinterests: "/pointofinterests",
    largemap: "/largemap",
    elasticsearchplants: "/elasticsearchplants",
    elasticsearchusers: "/elasticsearchusers",
    elasticsearchblogs: "/elasticsearchblogs",
    elasticsearchposts: "/elasticsearchposts",
    elasticsearchpois: "/elasticsearchpois",
    elasticsearchall: "/elasticsearchall",
    monthlyactivities: "/monthlyactivities"
}

const Views = {
    start: "start",
    login: "login",
    users: "users",
    userdetail: "userdetail",
    plants: "plants",
    planttags: "planttags",
    addplant: "addplant",
    inspiration: "inspiration",
    planttypes: "planttypes",
    fieldspertype: "fieldspertype",
    images: "images",
    colors: "colors"
};


export { Routes, Views }