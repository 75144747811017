import React, { useEffect, useState } from "react";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { ForumPostCard } from "../Forum/ForumPostCard";
import { AdminIcons } from "../Shared/AdminIcon";
import { ToolBar } from "../ToolBar/ToolBar";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { JwtImage, JwtPlantImage } from "../UI/ImageElements";
import { useElasticMenu } from "./useElasticMenu";
import { useElasticSearch } from "./useElasticSearch";

export const ElasticSearchPlants = () => {
    const ui = useElasticSearch();

    // useEffect(() => {
    //     ui.load();
    // }, []);
    const [elasticcheck_filterout_checked, setElasticcheck_filterout_checked] = useState<boolean>(true);

    const [search, setSearch] = useState<string>(undefined);
    const menu = useElasticMenu();
    return (
        <div className="screen-elastic">
            <ScreenHeader text="Appsök växt med Elastic Search" icon={AdminIcons.leaf} />
            <ScreenMenu items={menu?.items} />

            <LoadingBar loading={ui.loadingPlants} />
            <div>
                <input placeholder="Sök..." className="frm" type={"search"} value={search} onChange={e => setSearch(e.target.value)} onKeyUp={e => {
                    if (e.key == "Enter") {
                        ui.onSearchPlants(search, { filterOutSort: elasticcheck_filterout_checked });
                    }
                }} />
                <span>
                    <input type="checkbox" name="elasticcheck_filterout" id="elasticcheck_filterout" checked={elasticcheck_filterout_checked} onClick={() => setElasticcheck_filterout_checked(!elasticcheck_filterout_checked)} />
                    <label htmlFor="elasticcheck_filterout">Ta inte med växter med sort</label>
                </span>
            </div>

            <div>
                <div>
                    Träffar: {ui?.plants?.total ?? 0} - Svarstid: {ui.plants?.durationms} ms.
                </div>
                <div>

                    {(ui.plants?.suggestions?.length > 0) && (
                        <div className="suggestions-container">
                            <label>
                                Menade du?
                            </label>

                            <div className="suggestions">
                                {(ui.plants?.suggestions?.length > 0) && ui.plants?.suggestions?.map(s => (
                                    <div className="suggestion" key={s} onClick={() => {
                                        ui.onSearchPlants(s);
                                        setSearch(s);
                                    }} >
                                        {s}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                </div>
                <table className="tbl tbl-small">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Namn</th>
                            <th>Sort</th>
                            <th>Latin</th>
                            <th>Typ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.plants?.hits?.map(hit => (
                            <tr key={hit?.Id}>
                                <td className="img">
                                    <JwtPlantImage plantImageId={hit?.imageid} sizeType="small" />
                                </td>
                                <td>{hit?.Name}</td>
                                <td>{hit?.specifictype}</td>
                                <td>{hit?.namelatin}</td>
                                <td>{hit?.Type}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
